// Body
$primary: #00057B;
$secondary: #0153FF;
$bg-primary: #FFFFFF ;
$bg-secondary: #F2F2F2;
$bg-cancel-btn: #c4c4c4;
$text-white: #ffffff;
$text-black: #272727;
$text-gray: #707070;
$text-link: #0153FF;
$background-add-row: #F2F6FF;
$success: #1bcc5a;
$yellow: #F6AA1C;
$danger: #C90D0D;
$border-gray: #D7D7D7;
$excel-color: #10BE16;
// Typography
$font-family-sans-serif: 'Rajdhani', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
.text-white{
    color: #ffffff;
}
.bg-success{
    background-color: $success;
}
.bg-secondary{
    background-color: $secondary;
}
.bg-primary{
    background-color: $primary;
}
.text-gray-primary {
    color: $bg-primary;
}
.text-success {
    color: $success;
}
.text-gray-secondary {
    color: $bg-secondary;
}
